import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import { TokenTable } from '~/components/TokensTable/TokenTable';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader">
    </PageHeader>
    <p>{`Z-index bør brukes med omhu. Overforbruk av z-indexverdier kan føre til uforutsigbar adferd og vanskeligheter med vedlikehold. For å gjøre verdiene mer lesbare er det lurt å bruke tokens med forståelige navn, som våre tokens.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@use '@entur/tokens/dist/styles.scss';

.card {
    z-index: $z-indexes-behind;
}
`}</code></pre>
    <h2 {...{
      "id": "z-indexer"
    }}>{`Z-indexer`}</h2>
    <TokenTable tokenKey="zIndexes" mdxType="TokenTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      